import React, { useState, useEffect } from "react";
import LoadingIcon from "./LoadingIcon";
import VideoModal from "./VideoModal";

const GameToggleButtons = (props) => {
  const [showReveal, setShowReveal] = useState(true);
  const [showEndRound, setShowEndRound] = useState(false);
  const [showEndGame, setShowEndGame] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [showVideoButton, setShowVideoButton] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowEndRound(props.showEndRound);
  }, [props.showEndRound]);

  useEffect(() => {
    setShowEndGame(props.showEndGame);
  }, [props.showEndGame]);

  useEffect(() => {
    setShowReveal(props.showReveal);
  }, [props.showReveal]);

  useEffect(() => {
    setShowNext(props.showNext);
  }, [props.showNext]);

  useEffect(() => {
    var show =
      props.showPlayVideo && props.roundVideos && props.roundVideos.length > 0;
    setShowVideoButton(show);
  }, [props.roundVideos, props.showPlayVideo]);

  const handleNextQuestion = () => {
    props.onNextQuestion();
  };

  const handleReveal = () => {
    props.onReveal();
  };

  const handleEndRound = () => {
    props.onEndRound();
  };

  const handleEndGame = () => {
    props.onEndGame();
  };

  const showVideoModal = () => {
    if (showModal) {
      return;
    }
    props.onShowVideo();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    props.onEndVideo();
  };

  const handleShowVideo = () => {
    // props.onShowVideo();
  };

  return (
    <React.Fragment>
      <div className="btnSection text-right">
        {showEndRound && !props.loading && (
          <img
            onClick={() => handleEndRound()}
            src={props.translation('gameQuestion.endRoundImg')}
            className="imgBtn btn_endRound"
            alt=""
          />
        )}
        {showEndGame && !props.loading && (
          <img
            onClick={() => handleEndGame()}
            src={props.translation('gameQuestion.endGameImg')}
            className="imgBtn btn_endRound"
            alt=""
          />
        )}
        {showVideoButton && (
          <img
            onClick={() => showVideoModal()}
            src={props.translation('gameQuestion.playVideoImg')}
            className="imgBtn btn_playVideo"
            alt=""
          />
        )}
        {showReveal && !props.loading && (
          <img
            onClick={() => handleReveal()}
            src={props.translation('gameQuestion.revealImg')}
            className="imgBtn btn_reveal"
            style={{ width: "250px" }}
            alt=""
          />
        )}
        {showNext && !props.loading && (
          <img
            onClick={() => handleNextQuestion()}
            src={props.translation('nextButton')}
            className="imgBtn btn_next"
            style={{ width: "250px" }}
            alt=""
          />
        )}

        {props.loading && <LoadingIcon />}
      </div>

      <VideoModal
        modal={showModal}
        videos={props.roundVideos}
        onShowVideo={handleShowVideo}
        onModalClose={handleModalClose}
        translation={props.translation}
      />
    </React.Fragment>
  );
};

export default GameToggleButtons;

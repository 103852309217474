import React, { useState, useRef, useEffect } from "react";

import GamePlayerNameRow from "./GamePlayerNameRow";

const GamePlayersCheckInStatus = (props) => {
  const [gamePlayers, setGamePlayers] = useState([]);

  const getStatusCss = (gamePlayer) => {
    if (gamePlayer.online) return { color: "green" };

    return { color: "red" };
  };

  return (
    <React.Fragment>
      <div className="playerCheckInHeading">
        <img src={props.translation('checkingIn.playersCheckingInLg')} alt="" />
      </div>
      <div className="horizontalLine"></div>
      <div className="playerCheckInList">
        {props.gamePlayers.map((item) => (
          <GamePlayerNameRow
            name={item.fullName}
            active={item.online}
            key={item.id}
            id={item.id}
          />
        ))}
      </div>
      <div className="horizontalLine"></div>
    </React.Fragment>
  );
};

export default GamePlayersCheckInStatus;

import React, { useEffect } from 'react'
import parse from 'html-react-parser'

export const Footer = ({translation, ...props}) => {
  const addCookieNotice = () => {
    var elementExists = true

    var scriptEl = document.getElementById('cookieScript')
    if (!scriptEl) {
      scriptEl = document.createElement('script')
      scriptEl.id = 'cookieScript'
      elementExists = false
    }
    scriptEl.src = translation('footer.cookieScript')
    if (!elementExists) document.head.appendChild(scriptEl)
  }

  useEffect(() => {

    setTimeout(addCookieNotice, 500);
  }, [])

  return (
    <div className="LoginPage">
      <div className="horizontalLine"></div>
      <div className="footer auth font_footer">
        <div className="container text-center">
          <div style={{ inline: 'block' }}>
            <ul className="menu pull-center">
              <li>{parse(translation('footer.legalNotice'))}</li>
              <li>{parse(translation('footer.privacyPolicy'))}</li>
              <li>{parse(translation('footer.cookieNotice'))}</li>
              <li>{parse(translation('footer.reportAE'))}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

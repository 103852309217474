import React from "react";
import GameCheckInHeader from "./GameCheckInHeader";
import LoadingIcon from "./LoadingIcon";

export default function GameAboutToStart(props) {
  const handleGameAboutToStart = () => {
    props.onStartQuestion();
  };

  return (
    <div>
      <div className="gameAboutToStartPage pl-5 pr-5">
        <GameCheckInHeader
          gameRep={props.gameRep}
          gameModerator={props.gameModerator}
          translation={props.translation}
        />

        <div className="body pl-5 pr-5">
          <div className="left">
            <img src={props.translation('gameAboutToStart.bgImg')} alt="" />
          </div>
        </div>
        <div className="footer">
          <div className="bottom">
            {!props.loading && (
              <img
                className="imgBtn"
                src={props.translation('gameAboutToStart.startImg')}
                alt=""
                onClick={() => handleGameAboutToStart()}
              />
            )}

            {props.loading && <LoadingIcon />}
          </div>
        </div>
      </div>
    </div>
  );
}

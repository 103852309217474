const role = { "GameAdmin": 3, "GameModerator": 4, "GamePlayer": 5, "Rep": 6 };
const languages = {
    en: {
        name: "English",
        abbr: "en",
        id: 1
    },
    fr: {
        name: "French",
        abbr: "fr",
        id: 2
    }
};

const languagesList = [{
    name: languages.en.name,
    abbr: languages.en.abbr,
    id: languages.en.id
}, {
    name: languages.fr.name,
    abbr: languages.fr.abbr,
    id: languages.fr.id
}]


const getLanguageFromAbbr = (abbr) => {
    var filtered = languagesList.filter(l => l.abbr.toLowerCase() == abbr.toLowerCase());
    if (filtered.length == 0) {
        return [];
    }

    return filtered[0];
}

const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function(c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

const soundLevel = 0.8;
const uiSoundLevel = 0.3;

export default {
    role: role,
    languages: languages,
    languagesList: languagesList,
    getLanguageFromAbbr: getLanguageFromAbbr,
    uuidv4: uuidv4,
    soundLevel: soundLevel,
    uiSoundLevel: uiSoundLevel,
}
import React from "react";

const ViewingVideo = (props) => {
  return (
    <div className="endOfRound_winner videoOpen pl-5 pr-5">
      <div className="endOfRoundPage mobile pl-5 pr-5">
        <div className="logo">
          <img src={props.translation('logoSm')} alt="RISE Logo" />
        </div>
        <div className="options">
          <img
            src={props.translation('viewingVideoImg')}
            className="afterAnswer videoOpen"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ViewingVideo;

import React, { useEffect, useState } from "react";

const GameFinish = (props) => {
  const getClassName = () => {
    var myClassName = "";

    if (props.viewingVideo) {
      myClassName += "videoOpen ";
    }

    if (props.endGameStat.rank && props.endGameStat.rank == 1) {
      myClassName += "first ";
    }

    return myClassName;
  };

  return (
    <div className={getClassName() + "endOfGame_winner"}>
      <div className="endOfGamePage mobile pl-5 pr-5">
        <div className="logo">
          <img src={props.translation('logoSm')} alt="RISE Logo" />
        </div>
        <div className="roundAndQuestionNumber">
          <div className="roundNumber roundAndQuestion align-items-center">
            <div className="label">
              <img src={props.translation('endOfGame.endOfGame_mImg')} alt="" />
            </div>
          </div>
        </div>
        <div className="options">
          <img
            src={props.translation('endOfRound.yourScoreSmall')}
            className="yourScore"
            alt=""
          />
        </div>
        <div className="btnSection">
          <p>
            {props.endGameStat.points}
            <span>Pts</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GameFinish;

import React from "react";
import { FormGroup, Label, FormFeedback, Input as InputEl } from "reactstrap";

const Input = ({ name, label, customLabel, error, ...rest }) => {
  let inputClass = "form-control";
  if (error) inputClass += " is-invalid";

  return (
    <FormGroup>
      <Label for={name}>
        {customLabel && customLabel}
        {!customLabel && label}
      </Label>
      <InputEl name={name} id={name} className={inputClass} {...rest} />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

export default Input;

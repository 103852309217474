import { HubConnectionState } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const ConnectionStatusModal = (props) => {
  const [modal, setModal] = useState(false);
  const [connectionState, setConnectionState] = useState(
    HubConnectionState.Connected
  );

  useEffect(() => {
    setTimeout(function () {
      // setModal(true);
    }, 2000);
  }, []);

  useEffect(() => {
    setConnectionState(props.connectionState);


    if(!props.gameJwt){
      return;
    }

    setTimeout(function () {
      switch (props.connectionState) {
        case HubConnectionState.Disconnected:
        case HubConnectionState.Reconnecting:
          setModal(true);
          break;
        case HubConnectionState.Connected:
          setModal(false);
          break;
      }
    }, 1000);

    return () => {};
  }, [props.connectionState]);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    setModal(props.modal);
  }, [props.modal]);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Modal
      isOpen={modal}
      size="lg"
      toggle={toggle}
      backdrop="static"
      centered={true}
      className="connectionModal"
    >
      <ModalBody>
        <div>
          {connectionState &&
            connectionState == HubConnectionState.Disconnected && (
              <div>
                <div className="text-center">
                  <h3>{props.translation('connectionLost')}</h3>

                  <div>
                    <img src="/Assets/images/lungs_raining.svg" />
                  </div>
                  <Button
                    color="primary"
                    onClick={() => handleReload()}
                    className="mt-3"
                  >
                    {props.translation('clickToReconnect')}
                  </Button>
                </div>
              </div>
            )}
          {connectionState &&
            connectionState == HubConnectionState.Reconnecting && (
              <div className="text-center">
                <h3>{props.translation('reconnecting')}</h3>

                <div className="mt-3">
                  <img src="/Assets/images/beforeWeStart.svg" />
                </div>
              </div>
            )}
          {connectionState && connectionState == HubConnectionState.Connected && (
            <div className="text-center">
              <h3>Connected</h3>

              <div className="mt-3">
                <img src="/Assets/images/lungs_lg.svg" />
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConnectionStatusModal;

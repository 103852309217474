import http from "./httpService";
import { validateCodeUrl, gameStatusUrl, startGameUrl, viewVideoUrl, aboutToStartGameUrl, viewVideoEndUrl, endGameUrl, revealResutlUrl, submitAnswerUrl, gamePlayerStatusUrl, nextQuestionUrl, currentQuestionUrl, endRoundUrl } from "../components/config.json";
import jwtDecode from "jwt-decode";
import storage from "./../services/storageService";
import constants from './../components/customized/constants';
import storageService from "./../services/storageService";

const tokenKey = "gametoken";

http.setJwt(storage.getItem(tokenKey));

export async function validate(playerCode, gameCode, repId) {
    http.setJwt(storage.getItem(tokenKey));

    const { data: result } = await http.post(validateCodeUrl, { "playerCode": playerCode, "gameCode": gameCode, "repId": repId });

    var jwt = result.result.object;

    storage.setGameToken(jwt.auth_token);

    return result.result;
}

export function loginWithJwt(jwt) {
    storage.setItem(tokenKey, jwt);
}

export function logout() {
    storage.removeItem(tokenKey);
}

export function getToken() {
    try {
        const jwt = storage.getGameToken();
        return jwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export async function gameStatus() {
    var token = storage.getGameToken(true);
    if (token == null) {
        return null;
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(gameStatusUrl);
        storage.setGameStatus(result.result);

        return result.result;
    } catch (error) {
        if (error && error.response) {
            if (error.response.status === 401) {
                storageService.removeGameToken();
            }
        }
        return error;
    }
}

const getInvalidTokenError = () => {
    return {
        response: {
            status: 401,
        }
    }
}

export async function nextQuestion(data = {}) {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(nextQuestionUrl, data);

        return result.result;
    } catch (error) {
        return error;
    }
}

export async function submitAnswer(data = {}) {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(submitAnswerUrl, data);
        return result.result;
    } catch (error) {
        throw error;
    }
}

export async function revealAnswer(data = {}) {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(revealResutlUrl, data);

        return result.result;
    } catch (error) {
        throw error;
    }
}

export async function endRound(data = {}) {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(endRoundUrl, data);

        return result.result;
    } catch (error) {
        throw error;
    }
}


export async function endGame(data = {}) {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(endGameUrl, data);

        return result.result;
    } catch (error) {
        throw error;
    }
}

export async function viewVideo(data = {}) {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(viewVideoUrl, data);

        return result.result;
    } catch (error) {
        throw error;
    }
}

export async function viewVideoEnd(data = {}) {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(viewVideoEndUrl, data);

        return result.result;
    } catch (error) {
        throw error;
    }
}

export async function currentQuestion() {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(currentQuestionUrl);

        return result.result;
    } catch (error) {
        throw error;
    }
}

export async function gamePlayerStatus() {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(gamePlayerStatusUrl);

        return result.result;
    } catch (error) {

        return [];
    }
}

export async function startGame() {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(startGameUrl);

        return result.result;
    } catch (error) {
        throw error;
    }
}

export async function aboutToStartGame() {
    var token = storage.getGameToken(true);
    if (token == null) {
        throw getInvalidTokenError();
    }

    http.setJwt(storage.getGameToken(false));

    try {
        const { data: result } = await http.post(aboutToStartGameUrl);

        return result.result;
    } catch (error) {
        throw error;
    }
}

export function isAdmin() {
    const roleIds = storage.getRoleIds();
    return roleIds.includes(constants.role.Rep.toString());
}

export function isModerator() {
    const roleIds = storage.getRoleIds();
    return roleIds.includes(constants.role.GameModerator.toString());
}

export function isRep() {
    const roleIds = storage.getRoleIds();
    return roleIds.includes(constants.role.Rep.toString());
}

export default {
    validate,
    logout,
    loginWithJwt,
    getToken,
    gameStatus,
    gamePlayerStatus,
    nextQuestion,
    submitAnswer,
    revealAnswer,
    endRound,
    endGame,
    viewVideo,
    viewVideoEnd,
    currentQuestion,
    isAdmin,
    isModerator,
    isRep,
    startGame,
    aboutToStartGame
};
import jwtDecode from "jwt-decode";
import constants from "../components/customized/constants";


const audioSetting = "audioSetting";

function setAudioPref(mute = false) {
    localStorage.setItem(audioSetting, mute);
}

function getAudioPref() {
    var setting = localStorage.getItem(audioSetting);
    try {
        if (setting == null || setting == undefined) {
            return true;
        }
        return setting == "true";
    } catch (error) {
        return false;
    }
}

function getItem(tokenKey) {
    return localStorage.getItem(tokenKey);
}

function setItem(tokenKey, jwt) {
    localStorage.setItem(tokenKey, jwt);
}

function removeItem(tokenKey) {
    localStorage.removeItem(tokenKey)
}

const gameStatusKey = "gameStatus";

function setGameStatus(value) {
    localStorage.setItem(gameStatusKey, JSON.stringify(value));
}

function getGameStatus() {
    try {
        let value = localStorage.getItem(gameStatusKey);
        return JSON.parse(value);
    } catch {
        return null;
    }
}

const tokenKey = "token";

function setCurrentUser(value) {
    localStorage.setItem(tokenKey, value);
}

function getCurrentUser(decode = true) {
    try {
        const jwt = localStorage.getItem(tokenKey);
        if (decode) {
            return jwtDecode(jwt);
        }
        return jwt;
    } catch (ex) {
        return null;
    }
}

const gameTokenKey = "gametoken";

function setGameToken(value) {
    localStorage.setItem(gameTokenKey, value);
}

function removeGameToken() {
    localStorage.removeItem(gameStatusKey);
    localStorage.removeItem(gameTokenKey);
}

function getGameToken(decode = true) {
    try {
        const jwt = localStorage.getItem(gameTokenKey);
        if (decode) {
            return jwtDecode(jwt);
        }

        return jwt;
    } catch (ex) {
        return null;
    }
}

function getRoleIds() {
    const jwt = getGameToken(true);
    if (jwt == null) {
        return [];
    }

    const roleIds = jwt.roleId.split(',');
    return roleIds;
}

const languageToken = "i18nextLng";

// function setLanguage(language){
//     setItem(languageToken, language);
// }

function getLanguageId() {
    return getLanguage().id;
}

function getLanguage() {
    try {

        const language = localStorage.getItem(languageToken);
        if (language) {
            switch (language) {
                case constants.languages.en.abbr:
                    return constants.languages.en;

                case constants.languages.fr.abbr:
                    return constants.languages.fr;

                default:
                    return constants.languages.en;
            }
        }

    } catch (ex) {
        return constants.languages.en;
    }

    return constants.languages.en;
}

export default {
    getAudioPref,
    setAudioPref,
    getItem,
    setItem,
    removeItem,
    getGameStatus,
    setGameStatus,
    setCurrentUser,
    getCurrentUser,
    setGameToken,
    getGameToken,
    removeGameToken,
    getRoleIds,
    getLanguageId,
    // setLanguage,
    getLanguage
}
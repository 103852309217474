import React from "react";
import LoadingIcon from "./LoadingIcon";

export default function GameBeforeWeStart(props) {
  return (
    <div>
      <div className="checkInPage mobile pl-5 pr-5">
        <div className="logo">
          <img src={props.translation('logoSm')} alt="RISE Logo" />
        </div>
        <div className="playerCheckInHeading">
          <img src={props.translation('beforeWeStart.beforeWeStartText')} alt="" />
        </div>
        <div className="lungsImg">
          {!props.lading && (
            <img src={props.translation('beforeWeStart.beforeWeStartImg')} alt="" />
          )}
          {props.loading && <LoadingIcon />}
        </div>
      </div>
    </div>
  );
}

import http from "./httpService";
import { authUrl } from "../components/config.json";
import jwtDecode from "jwt-decode";
import storage from "./../services/storageService";
const apiEndPoint = authUrl;
const tokenKey = "token";

http.setJwt(storage.getItem(tokenKey));

export async function login(email, password) {
  const { data: jwt } = await http.post(apiEndPoint, { "username": email, "password": password });
  storage.setCurrentUser(jwt.auth_token);
}

export function loginWithJwt(jwt) {
  storage.setItem(tokenKey, jwt);
}

export function logout() {
  storage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = storage.getCurrentUser();
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export default {
  login,
  logout,
  loginWithJwt,
  getCurrentUser,
};

import React, { useState, useEffect, useRef } from "react";
import GameCheckIn from "./GameCheckIn";
import GameNotFound from "./GameNotFound";
import GameStartingShortly from "./GameStartingShortly";
import GameQuestion from "./GameQuestion";
import GameRoundFinish from "./GameRoundFinish";
import GameLeaderBoard from "./GameLeaderBoard";
import GameQuestionAnswerKeypad from "./GameQuestionAnswerKeypad";
import GameRoundLeaderBoard from "./GameRoundLeaderBoard";
import ViewingVideo from "./ViewingVideo";
import GameFinish from "./GameFinish";
import GameAboutToStart from "./GameAboutToStart";
import GameBeforeWeStart from "./GameBeforeWeStart";
import { Route, useParams, useLocation } from "react-router";

const GamePlay = (props) => {
  const handleCheckIn = async () => {
    props.onCheckIn();
  };

  const handleGameStart = async () => {
    props.onGameStart();
  };

  const handleStartQuestion = async () => {
    props.onStartQuestion();
  };

  const handleNextQuestion = async () => {
    props.onNextQuestion();
  };

  const handleSubmitAnswer = async (data) => {
    props.onSubmitAnswer(data);
  };

  const handleNextRound = async () => {
    props.onNextRound();
  };

  const handleReveal = async () => {
    props.onReveal();
  };

  const handleEndRound = async () => {
    props.onEndRound();
  };

  const handleShowVideo = async () => {
    props.onShowVideo();
  };

  const handleEndVideo = async () => {
    props.onEndVideo();
  };

  const handleEndGame = async () => {
    props.onEndGame();
  };

  if (props.viewingVideo) {
    return <ViewingVideo translation={props.translation} />;
  } else if (props.question) {
    if (props.isRep) {
      return (
        <GameQuestion
          question={props.question}
          displayRoundId={props.displayRoundId}
          displayQuestionId={props.displayQuestionId}
          roundVideos={props.roundVideos}
          onNextQuestion={handleNextQuestion}
          onReveal={handleReveal}
          revealQuestionResult={props.revealQuestionResult}
          showReveal={!props.revealQuestionResult}
          onEndRound={handleEndRound}
          playersAnswerStatus={props.playersAnswerStatus}
          onShowVideo={handleShowVideo}
          onEndVideo={handleEndVideo}
          showGameNavigationButtons={props.isRep}
          loading={props.loading}
          translation={props.translation}
        />
      );
    }
    return (
      <GameQuestionAnswerKeypad
        question={props.question}
        displayRoundId={props.displayRoundId}
        displayQuestionId={props.displayQuestionId}
        questionResult={props.questionResult}
        revealQuestionResult={props.revealQuestionResult}
        onSubmitAnswer={handleSubmitAnswer}
        translation={props.translation}
      />
    );
  } else if (props.endGameStat) {
    if (props.isRep) {
      return (
        <GameLeaderBoard
          endGameStat={props.endGameStat}
          translation={props.translation}
        />
      );
    }

    return (
      <GameFinish
        endGameStat={props.endGameStat}
        translation={props.translation}
      />
    );
  } else if (props.endRoundStat) {
    if (props.isRep) {
      return (
        <GameRoundLeaderBoard
          onNextRound={handleNextRound}
          endRoundStat={props.endRoundStat}
          displayRoundId={props.displayRoundId}
          onShowVideo={handleShowVideo}
          onEndVideo={handleEndVideo}
          isLastRound={props.isLastRound}
          onEndGame={handleEndGame}
          roundVideos={props.roundVideos}
          showGameNavigationButtons={props.isRep}
          translation={props.translation}
        />
      );
    }

    return (
      <GameRoundFinish
        onNextRound={handleNextRound}
        loading={props.loading}
        viewingVideo={props.viewingVideo}
        displayRoundId={props.displayRoundId}
        endRoundStat={props.endRoundStat}
        translation={props.translation}
      />
    );
  } else if (props.gameStatus) {
    if (props.gameStatus.active) {
      if (props.gameStatus.finished) {
        return <h1>Game Finished</h1>;
      }

      if (props.gameStatus.aboutToStart) {
        if (props.isRep) {
          return (
            <GameAboutToStart
              onStartQuestion={handleStartQuestion}
              gameRep={props.gameRep}
              gameModerator={props.gameModerator}
              gamePlayers={props.gamePlayers}
              loading={props.loading}
              translation={props.translation}
            />
          );
        }

        return (
          <GameBeforeWeStart
            onStartQuestion={handleStartQuestion}
            gamePlayers={props.gamePlayers}
            loading={props.loading}
            translation={props.translation}
          />
        );
      }

      if (props.gameStatus.started) {
        return (
          <GameCheckIn
            onCheckIn={handleCheckIn}
            gameRep={props.gameRep}
            gameModerator={props.gameModerator}
            gamePlayers={props.gamePlayers}
            loading={props.loading}
            translation={props.translation}
          />
        );
      }

      if (props.gameStatus.active) {
        return (
          <GameStartingShortly
            gameToken={props.gameToken}
            onGameStart={handleGameStart}
            loading={props.loading}
            translation={props.translation}
          />
        );
      }

      return "";
    } else {
      return <GameNotFound></GameNotFound>;
    }
  }

  return <h1>Please Wait</h1>;
};

export default GamePlay;

import React, { useRef, useState, useEffect } from "react";
import GameQuestionAnswer from "./GameQuestionAnswer";
import GameToggleButtons from "./GameToggleButtons";
import GameQuestionHeader from "./GameQuestionHeader";
import GamePlayerAnswerStatus from "./GamePlayerAnswerStatus";
import parse from "html-react-parser";
import $ from "jquery";

const GameQuestion = (props) => {
  const [question, setQuestion] = useState();
  const [showReveal, setShowReveal] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(null);

  const dQuestionTextRef = useRef(null);
  const dQuestionSection = useRef(null);

  const minFontSize = 14;

  useEffect(() => {
    setTimeout(function () {
      setFontSize();
    }, 150);
  }, []);

  useEffect(() => {
    setQuestion(props.question);

    setTimeout(function () {
      setFontSize();
    }, 150);
  }, [props.question]);

  useEffect(() => {
    setShowReveal(props.showReveal);

    setTimeout(function () {
      setFontSize();
    }, 150);
  }, [props.showReveal]);

  const handleNextQuestion = () => {
    props.onNextQuestion();
  };

  const handleEndRound = () => {
    props.onEndRound();
  };

  const handleReveal = () => {
    props.onReveal();
  };

  const handleShowVideo = () => {
    props.onShowVideo();
  };

  const handleEndVideo = () => {
    props.onEndVideo();
  };

  //Adjusts the Font Size Until the Container Height is Equal to the Text Height
  const setFontSize = () => {
    if (!(dQuestionTextRef && dQuestionTextRef.current)) {
      return;
    }

    let containerHeight = dQuestionSection.current.clientHeight;
    let textHeight = dQuestionTextRef.current.clientHeight;

    const computedSize = window
      .getComputedStyle(dQuestionTextRef.current)
      .getPropertyValue("font-size");
    if (!computedSize) {
      return;
    }

    let fontSize = parseInt(computedSize.replace("px", ""));

    let currentFontSize = fontSize;
    while (currentFontSize > minFontSize && textHeight >= containerHeight) {
      containerHeight = dQuestionSection.current.clientHeight;
      textHeight = dQuestionTextRef.current.clientHeight;

      currentFontSize -= 2;
      dQuestionTextRef.current.style.fontSize = currentFontSize + "px";
    }
  };

  return (
    <div className="questionInPage pl-xl-5 pr-xl-5 pl-2 pr-2">
      {question && (
        <React.Fragment>
          <GameQuestionHeader
            roundId={props.displayRoundId}
            showAsHeader={true}
            questionId={props.displayQuestionId}
            translation={props.translation}
          />

          <div className="body pl-5 pr-5">
            <div className="questionSection" ref={dQuestionSection}>
              <div className="question">
                <div className="questionText" ref={dQuestionTextRef}>
                  {parse(question.question)}
                </div>
              </div>
              <div className="lungsImg">
                {!props.revealQuestionResult && (
                  <img src="Assets/images/questionLungs_lg.svg" alt="" />
                )}
                {props.revealQuestionResult && (
                  <img
                    src="Assets/images/questionLungsRevealed_lg.svg"
                    alt=""
                  />
                )}
              </div>
            </div>

            <GameQuestionAnswer
              question={question}
              showCorrectAnswer={props.revealQuestionResult}
              translation={props.translation}
            />

            {props.showGameNavigationButtons && (
              <div className="row">
                <div className="col-7">
                  <GamePlayerAnswerStatus
                    players={props.playersAnswerStatus}
                    revealQuestionResult={props.revealQuestionResult}
                    translation={props.translation}
                  />
                </div>
                <div className="col-5">
                  <GameToggleButtons
                    onNextQuestion={handleNextQuestion}
                    showEndRound={true}
                    showReveal={showReveal}
                    showPlayVideo={!showReveal}
                    onShowVideo={handleShowVideo}
                    onEndVideo={handleEndVideo}
                    showNext={!showReveal}
                    onEndRound={handleEndRound}
                    roundVideos={props.roundVideos}
                    onReveal={handleReveal}
                    loading={props.loading}
                    translation={props.translation}
                  />
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GameQuestion;

import React, { useState, useEffect } from "react";
import Arrow from "./Arrow";

const GamePlayerAnswerStatus = (props) => {
  const [showQuestionResult, setShowQuestionResult] = useState(false);

  useEffect(() => {
    setShowQuestionResult(props.revealQuestionResult);
  }, [props.revealQuestionResult]);

  const hasAnswered = (item) => {
    var css = "arrowAndPlayerName";
    if (!item.online) {
      css += " inactive";
    }
    return css;
  };

  const defaultFill = "#821A51";

  const getFillColor = (item) => {
    // if(item && item.label && showQuestionResult){
    //   return item.label.color;
    // }

    return defaultFill;
  };

  return (
    <div className="playerListAndBtn">
      <div className="playerList">
        {props.players.map((item) => (
          <div className={hasAnswered(item)} key={item.id}>
            <div className="arrow">
              {item.online && <Arrow fill={getFillColor(item)} />}
              {item.online && item.answered && <img src="/assets/images/arrow_checked.svg" />}
            </div>
            <div className="playerNameText">
              <div className="label">{item.fullName}</div>
            {/* {!item.online && <div class="overlay"></div>} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GamePlayerAnswerStatus;

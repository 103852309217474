import React, { useEffect, useState } from 'react'
import Joi from 'joi-browser'
import { Row, Col } from 'reactstrap'
import Form from './../common/form'
import game from '../../services/gameService'
import { useParams } from 'react-router'
import { useLocation, BrowserRouter as Router } from 'react-router-dom'
import { Footer } from '../Footer'

const GameValidateCode = (props) => {
  const [data, setData] = useState({ playerCode: '', gameCode: '', repId: 0 })
  const [errors, setErrors] = useState({})
  let { lang = '' } = useParams()

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  let gameCode = '',
    playerCode = ''
  gameCode = query.get('gameCode') ?? ''
  playerCode = query.get('playerCode') ?? ''

  const requiredOption = {
    language: {
      any: {
        empty: 'is required',
      },
      string: {
        email: 'invalid',
      },
    },
  }

  useEffect(() => {
    var myData = { playerCode: playerCode, gameCode: gameCode }
    setData(myData)

    if (
      myData.playerCode &&
      myData.playerCode != '' &&
      myData.gameCode &&
      myData.gameCode != ''
    ) {
      doSubmit(myData)
    }
  }, [])

  useEffect(() => {
    if (props.gameJwt) {
      props.history.push(`/${props.currentLanguage}/play`)
    }
  }, [props.gameJwt])

  const schema = {
    playerCode: Joi.string()
      .required()
      .label('Player Code')
      .options(requiredOption),
    gameCode: Joi.string()
      .required()
      .label('Game Code')
      .options(requiredOption),
    repId: Joi.number(),
  }

  const doSubmit = async (myData) => {
    try {
      var result = await game.validate(
        myData.playerCode,
        myData.gameCode,
        myData.repId,
      )

      props.onValidateCode(result)
    } catch (ex) {
      const myErrors = { ...errors }
      if (ex.response) {
        if (ex.response.status >= 400 && ex.response.status < 500) {
          myErrors.message = ex.response.data.message
        }
      } else {
        myErrors.message = ''
      }

      setErrors(myErrors)
    }
  }

  const formElements = [
    {
      name: 'gameCode',
      label: 'gameCode',
      customLabel: (
        <img src={props.translation('gameValidateCode.gameCodeImg')} />
      ),
      type: 'input',
    },
    {
      name: 'playerCode',
      label: 'playerCode',
      customLabel: <img src="Assets/images/playerCode.svg" />,
      type: 'input',
    },
    {
      name: 'login',
      label: 'Login',
      type: 'customSubmit',
      class: 'btn login-btn',
    },
  ]

  return (
    <>
      <main>
        <div className="LoginPage mobile pl-5 pr-5">
          <div className="logo">
            <img src={props.translation('logoSm')} alt="RISE Logo" />
          </div>
          <div className="horizontalLine"></div>
          <div className="cookieNoticeHeader">
            {props.translation('gameValidateCode.loginNote')}
          </div>
          <Row className="px-0 m-0 w-100 loginSectionWrapper">
            <Col sm={12} className="login-section-wrapper">
              {/* <div className="brand-wrapper"></div> */}
              <div className="login-wrapper my-auto">
                <Form
                  onSubmit={() => this.handleSubmit}
                  doSubmit={doSubmit}
                  data={data}
                  errors={errors}
                  schema={schema}
                  formElements={formElements}
                ></Form>
              </div>
            </Col>
          </Row>
        </div>
      </main>
      <Footer {...props} />
    </>
  )
}

export default GameValidateCode

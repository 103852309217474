import React, { useState, useEffect } from "react";

const GamePlayerNameRow = (props) => {
  const [id, setId] = useState(Math.floor(Math.random() * 10000) + 1);
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [icon, setIcon] = useState();

  useEffect(() => {
    setId(props.id);
    setName(props.name);
    setActive(props.active);
    setIcon(props.icon);
  }, [props]);

  const getClassName = (item) => {
    var className = "arrowAndPlayerName";
    if (item.active) {
      className += " active";
    }

    return className;
  };

  return (
    <div className={getClassName(props)}>
      <div className="arrow">
        <img src="Assets/images/arrow.svg" alt="" />
      </div>
      <div className="playerNameText">
        <div className="label">{name}</div>
      </div>
    </div>
  );
};

export default GamePlayerNameRow;

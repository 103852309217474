import React from "react";

const GameQuestionHeader = (props) => {
  const body = (
    <React.Fragment>
      <div className="logo">
        <img
          src={props.translation('logoLg')}
          className="logo_lg"
          alt="RISE Logo"
        />
        <img
          src={props.translation('logoSm')}
          className="logo_sm"
          alt="RISE Logo"
        />
      </div>
      <div className="roundAndQuestionNumber">
        <div className="roundNumber roundAndQuestion d-flex align-items-center">
          <div className="label">
            <img src={props.translation('gameQuestion.roundImg')} alt="" />
          </div>
          <div className="number">{props.roundId}</div>
        </div>
        <div
          className="
        questionNumber
        roundAndQuestion
        d-flex
        align-items-center mt-4 ml-3"
        >
          <div className="label">
            <img src={props.translation('gameQuestion.questionImg')} alt="" />
          </div>
          <div className="number">{props.questionId}</div>
        </div>
      </div>
    </React.Fragment>
  );

  if (props.showAsHeader) {
    return <header className="pt-4 d-flex">{body}</header>;
  }

  return body;
};

export default GameQuestionHeader;

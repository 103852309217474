import React from "react";

const GameCheckInHeader = (props) => {
  return (
    <header className="pt-4 d-flex">
      <div className="logo">
        <img src={props.translation('logoLg')} alt="RISE Logo" />
      </div>
      <div className="hostAndModeratorName">
        {props.gameRep && (
          <div className="hostName hostAndModerator d-flex align-items-center">
            <div className="label">
              <img src={props.translation('hostImg')} alt="" />
            </div>
            <div className="name">{props.gameRep.fullName}</div>
          </div>
        )}
        {props.gameModerator && (
          <div className="ModeratorName hostAndModerator d-flex align-items-center">
            <div className="label">
              <img src={props.translation('moderatorImg')} alt="" />
            </div>
            <div className="name">{props.gameModerator.fullName}</div>
          </div>
        )}
      </div>
    </header>
  );
};

export default GameCheckInHeader;

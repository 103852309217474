import React, { useEffect } from "react";

const GameQuestionResult = (props) => {

  if (props.questionResult) {
    return (
      <React.Fragment>
        {props.questionResult.correct && (
          <div className="options">
            <img
              src={props.translation('gameQuestion.correctImg')}
              alt=""
              className="imgCorrect"
            />
          </div>
        )}
        {!props.questionResult.correct && (
          <div className="options">
            <img
              src={props.translation('gameQuestion.incorrectImg')}
              className="afterAnswer incorrectImg"
              alt=""
            />
          </div>
        )}
        <div className="btnSection revealed">
          <p>+{props.questionResult.points}</p>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="options">
      <img
        src={props.translation('gameQuestion.incorrectImg')}
        className="afterAnswer incorrectImg"
        alt=""
      />
    </div>
  );
};

export default GameQuestionResult;

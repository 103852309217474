import React, { useState, useEffect } from "react";
import storageService from "../../services/storageService";
import game from "../../services/gameService";
import LoadingIcon from "./LoadingIcon";

const GameStartingShortly = (props) => {
  const [gameToken, setGameToken] = useState(props.gameToken);
  const [gameJwt, setGameJwt] = useState(null);

  const [isRep, setIsRep] = useState(game.isRep);
  const handleTokenChange = () => {
    let jwt = storageService.getGameToken(true);
    setGameJwt(jwt);

    if (jwt == null) {
      return;
    }

    let isRep = game.isRep();
    setIsRep(isRep);
  };

  useEffect(() => {
    setGameToken(props.gameToken);
    handleTokenChange();
  }, [props.gameToken]);

  const handleOnGameStart = () => {
    props.onGameStart();
  };

  return (
    <React.Fragment>
      {isRep && (
        <div className="welcomePage pl-xl-5 pr-xl-5 pl-2 pr-2">
          <div className="body">
            <div className="welcomePage_bigImg">
              <img src={props.translation('gameStartingShortly.background')} alt="" />
            </div>

            <div className="playerListAndBtn">
              <div className="btnSection">
                {!props.loading && (
                  <img
                    src={props.translation('nextButton')}
                    className="btn btn_next"
                    alt=""
                    onClick={handleOnGameStart}
                  />
                )}
                {props.loading && <LoadingIcon />}
              </div>
            </div>
          </div>
        </div>
      )}
      {!isRep && (
        <div className="welcomePage mobile pl-5 pr-5">
          <div className="welcomePage_bigImg">
            <div>
              <img src={props.translation('gameStartingShortly.welcome1')} alt="" />
            </div>
            <div>
              <img src={props.translation('gameStartingShortly.welcome3')} alt="" />
            </div>
            <div>
              <img src={props.translation('gameStartingShortly.welcome2')} alt="" />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GameStartingShortly;

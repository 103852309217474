import React, { useState, useEffect } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Button,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";

const NavMenu = (props) => {

  const [collapsed, setCollapsed] = useState(false);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    props.onLogout();
  };

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        light
      >
        <Container>
          <NavbarBrand tag={Link} to="/">
            RVCC
          </NavbarBrand>
          <NavbarToggler onClick={() => toggleNavbar()} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow">
            {props.currentLanguage && props.languages && (
                <NavItem>
                  <Button
                    outline
                    color="primary"
                    className="language-icon"
                    onClick={() => props.onLanguageChange(props.languages.abbr)}
                  >
                    {props.languages.abbr.toUpperCase()}
                  </Button>
                </NavItem>
              )}
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to={"/" + props.currentLanguage + "/play"}>
                  Play
                </NavLink>
              </NavItem>
              {!props.gameJwt && (
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/login">
                    Login
                  </NavLink>
                </NavItem>

              )}
              {props.gameJwt && (
                <NavItem>
                  <a
                    role="button"
                    className="text-dark nav-link"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </a>
                </NavItem>
              )}
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;

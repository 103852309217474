import React, { useEffect, useState } from "react";
import GameToggleButtons from "./GameToggleButtons";
import _ from "lodash";

const GameRoundLeaderBoard = (props) => {
  const [endRoundStats, setEndRoundStats] = useState(props.endRoundStat);
  const [top5Players, setTop5Players] = useState([]);
  const [restPlayers, setRestPlayers] = useState([]);

  useEffect(() => {
    var players = props.endRoundStat;
    if (players.length == 0) {
      setEndRoundStats([]);
      return;
    }

    var top5 = [];
    var rest = [];

    for (var i = 0; i < players.length; i++) {
      let playerPoint = players[i];
      if (i < 1) {
        top5.push(playerPoint.players);
        continue;
      }

      rest.push(playerPoint.players);
    }

    setTop5Players(_.flatten(top5));
    setRestPlayers(_.flatten(rest));

    setEndRoundStats(props.endRoundStat);
  }, [props.endRoundStat]);

  const handleNextRound = () => {
    props.onNextRound();
  };

  const handleShowVideo = () => {
    props.onShowVideo();
  };

  const handleEndVideo = () => {
    props.onEndVideo();
  };

  const handleEndGame = () => {
    props.onEndGame();
  };
  return (
    <div className="endOfRoundPage pl-5 pr-5">
      <header className="pt-4 d-flex">
        <div className="logo">
          <img src={props.translation("logoLg")} alt="RISE Logo" />
        </div>
        <div className="hostAndModeratorName">
          <div className="hostName hostAndModerator d-flex align-items-center">
            <div className="label">
              <img src={props.translation("endOfRound.endOfRoundImg")} alt="" />
            </div>
            <div className="name">{props.displayRoundId}</div>
          </div>
        </div>
      </header>
      <div className="body pl-5 pr-5">
        <div className="left">
          <div className="playerCheckInHeading">
            <img src={props.translation('winnerImg')} alt="" />
          </div>
          <div className="playerCheckInList">
            {top5Players.map((item) => (
              <div className="arrowAndPlayerName" key={item.id}>
                <div className="playerNameText">
                  <div className="label"> {item.fullName}</div>
                </div>
                <div className="playerScore">
                  {item.points}
                  <span>pts</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          <div className="top">
            {restPlayers && restPlayers.length > 0 && (
              <div className="otherScoreBoard">
                <div className="heading">{props.translation('endOfRound.otherScore')}</div>
                <div className="horizontalLine"></div>
                <div className="playerCheckInList">
                  {restPlayers.map((item) => (
                    <div className="arrowAndPlayerName" key={item.id}>
                      <div className="playerNameText">
                        <div className="label">{item.fullName}</div>
                      </div>
                      <div className="playerScore">
                        {item.points}
                        <span>pts</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <img src={props.translation("endOfRound.lungsImg")} alt="" />
          </div>
          {props.showGameNavigationButtons && (
            <GameToggleButtons
              showPlayVideo={true}
              onShowVideo={handleShowVideo}
              onEndVideo={handleEndVideo}
              onEndGame={handleEndGame}
              showNext={true}
              showEndGame={!props.isLastRound}
              roundVideos={props.roundVideos}
              onNextQuestion={handleNextRound}
              translation={props.translation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GameRoundLeaderBoard;

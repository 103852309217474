import axios from "axios";
import storageService from "./storageService";

// axios.interceptors.response.use(null, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error

//   // toast.error("An unexpected error occurred");

//   return Promise.reject(error);
// });

axios.interceptors.request.use((config) => {
  var postObject = { "languageId": storageService.getLanguageId() };
  if (config.data) {
    postObject.object = config.data;
  }

  config.data = postObject;

  return config;
}, (error) => {
  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};

import React, { useEffect, useState } from "react";
import NavMenu from "./NavMenu";
import { ToastContainer } from "react-toastify";
import { Detector } from "react-detect-offline";
import storageService from "../services/storageService";
import constants from "./customized/constants";
import { Button } from "reactstrap";

const Layout = (props) => {
  const baseClass = "fade network-status";

  const [languages, setLanguages] = useState(constants.languagesList[0]);

  const [showNavMenu, setShowNavMenu] = useState(false);
  const [networkOnline, setNetworkStatus] = useState(true);

  const [audio, setAudio] = useState(storageService.getAudioPref());

  const [user, setUser] = useState(props.user);
  const [networkCss, setNetworkCss] = useState(
    baseClass + (!networkOnline ? " show" : " hide")
  );

  const toggleAudio = () => {
    var pref = !audio;
    storageService.setAudioPref(pref);
    setAudio(pref);

    props.onAudioPrefChange();
  };

  useEffect(() => {
    setUser(props.user);
  }, [props]);

  useEffect(() => {
    setNetworkStatus(networkOnline);
  }, [networkOnline]);

  useEffect(() => {
    let myLang = constants.languagesList.filter(
      (r) => r.abbr != props.currentLanguage
    )[0];

    setLanguages(myLang);

  }, [props.currentLanguage]);

  const handleNetworkChange = (online) => {
    setNetworkStatus(online);

    var className = "";

    if (online) {
      className = " online show";
      setNetworkCss(baseClass + className);

      setTimeout(function () {
        className = "hide";
        setNetworkCss(baseClass + className);
      }, 2000);
    } else {
      className += " show";
    }

    setNetworkCss(baseClass + className);
  };

  const handleShowNav = () => {
    setShowNavMenu(!showNavMenu);
  };

  const handleLogout = () => {
    props.onLogout();
  };

  const handleShowConnection = () => {
    props.onShowConnectionModal();
  };

  const handleLanguageChange = (lang) => {
    props.onLanguageChange(lang);
  };

  return (
    <div>
      <button
        className="btn btn-transparent"
        style={{ position: "absolute", zIndex: 10000 }}
        onClick={() => handleShowNav()}
      >
        &nbsp;
      </button>

      <div className="speaker-icon" onClick={() => toggleAudio()}>
        {audio && <img src="/Assets/images/speakericon.png" />}
        {!audio && <img src="/Assets/images/speakericon-mute.png" />}
      </div>

      {/* {props.currentLanguage && languages && (
        <Button
          outline
          color="primary"
          className="language-icon"
          onClick={() => props.onLanguageChange(languages.abbr)}
        >
          {languages.abbr.toUpperCase()}
        </Button>
      )} */}

      {/* 
      <button
        className="btn btn-transparent"
        style={{ position: "absolute", zIndex: 10000 }}
        onClick={() => handleShowConnection()}
      >
        Show Modal
      </button> */}
      {showNavMenu && (
        <NavMenu
        currentLanguage={props.currentLanguage}
        languages={languages}
          onLogout={handleLogout}
          gameJwt={props.gameJwt}
          currentLanguage={props.currentLanguage}
          onLanguageChange={handleLanguageChange}
        />
      )}
      <Detector
        polling={{ enabled: false }}
        onChange={handleNetworkChange}
        render={() => {
          return "";
        }}
      />
      <div className={networkCss}>
        You are currently {networkOnline ? "online" : "offline"}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
      {props.children}
    </div>
  );
};

export default Layout;

import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedGameRoute = ({
  path,
  component: Component,
  gameJwt,
  gameStatus,
  render,
  location,
  currentLanguage,
  ...rest
}) => {
  if (gameJwt) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Component
            gameJwt={gameJwt}
            gameStatus={gameStatus}
            location={location}
            {...rest}
            {...props}
          />
        )}
      />
    );
  }

  return <Redirect to={{ pathname: `/${currentLanguage}/login`, state: { from: location } }} />;
};

export default ProtectedGameRoute;

import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Form from "./../common/form";
import GameQuestionHeader from "./GameQuestionHeader";
import GameQuestionResult from "./GameQuestionResult";
import { Howl } from "howler";
import constants from "../customized/constants";

const GameQuestionAnswerKeypad = (props) => {
  const [question, setQuestion] = useState(props.question);
  const [formElements, setFormElements] = useState([]);
  const [questionResult, setQuestionResult] = useState();
  const [revealQuestionResult, setRevealQuestionResult] = useState(false);

  const defaultAnswer = { answer: "" };

  const loginButton = {
    name: "ENTER",
    label: props.translation("gameQuestion.enterBtn"),
    type: "customSubmit",
    class: "btn",
    visible: false,
    allowSubmit: true,
  };

  const submittedButton = {
    name: "SUBMITTED",
    label: props.translation("gameQuestion.submittedBtn"),
    type: "customSubmit",
    class: "btn",
    visible: false,
    allowSubmit: false,
  };

  const [playSubmit, setPlaySubmit] = useState();
  const [playSuccess, setPlaySuccess] = useState();
  const [playFailure, setPlayFailure] = useState();

  useEffect(() => {
    let myPlaySubmit = new Howl({
      src: "/Assets/sounds/pop.mp3",
      volume: constants.uiSoundLevel,
      preload: true,
    });

    let myPlaySuccess = new Howl({
      src: "/Assets/sounds/success3.wav",
      volume: constants.uiSoundLevel,
      preload: true,
    });

    let myPlayFailuyre = new Howl({
      src: "/Assets/sounds/incorrect.wav",
      volume: constants.uiSoundLevel,
      preload: true,
    });

    setPlaySubmit(myPlaySubmit);
    setPlaySuccess(myPlaySuccess);
    setPlayFailure(myPlayFailuyre);
  }, []);

  const populateFormElements = () => {
    var elements = [];

    props.question.answers.forEach((el) => {
      let element = {
        name: getItemId(el),
        value: el.id,
        label: el.label.label,
        background: el.label.color,
        selectedBackground: "white",
        selectedBorder: el.label.color,
        selectedFontColor: el.label.color,
        soundEffects: true,
        group: "answer",
        type: "customRadio",
      };

      elements.push(element);
    });

    elements.push(loginButton);
    elements.push(submittedButton);
    setFormElements(elements);
  };

  useEffect(() => {
    loginButton.visible = props.question && true;
    submittedButton.visible = !(props.question && true);
    populateFormElements();

    setQuestion(props.question);
    setData(defaultAnswer);
  }, [props.question]);

  useEffect(() => {
    setQuestionResult(props.questionResult);

    loginButton.visible = !props.questionResult;
    submittedButton.visible = props.questionResult;

    populateFormElements();

    if (
      props.questionResult &&
      props.questionResult.answerIds &&
      props.questionResult.answerIds.length > 0
    ) {
      var Value = props.questionResult.answerIds[0].toString();
      setData({ answer: Value });
    }
  }, [props.questionResult]);

  useEffect(() => {
    setRevealQuestionResult(props.revealQuestionResult);

    loginButton.visible = !props.revealQuestionResult;
    submittedButton.visible = props.revealQuestionResult;

    if (questionResult) {
      if (questionResult.correct) {
        playSuccess.play();
      } else {
        playFailure.play();
      }
    }

    populateFormElements();
  }, [props.revealQuestionResult]);

  const getQuestionInPageClass = () => {
    if (questionResult && !revealQuestionResult) {
      return " answerSubmitted";
    }
    return "";
  };

  const getColor = (item) => {
    return { color: item.label.color };
  };

  const getItemId = (item) => {
    return `Option${item.id}`;
  };

  const [data, setData] = useState(defaultAnswer);
  const [errors, setErrors] = useState({});

  const requiredOption = {
    language: {
      any: {
        empty: "is required",
      },
      string: {
        email: "invalid",
      },
    },
  };

  const schema = {
    answer: Joi.number().integer().required().options(requiredOption),
  };

  const doSubmit = async (myData) => {
    var answerIds = [];
    setData(myData);

    var isArray = myData.answer instanceof Array;
    if (isArray) {
      myData.answer.forEach((item) => {
        answerIds.push(parseInt(item));
      });
    } else {
      answerIds.push(parseInt(myData.answer));
    }

    var submitData = { questionId: question.id, answerIds: answerIds };

    playSubmit.play();

    props.onSubmitAnswer(submitData);
  };

  return (
    <div className="questionInPage mobile pl-5 pr-5">
      <GameQuestionHeader
        roundId={props.displayRoundId}
        questionId={props.displayQuestionId}
        translation={props.translation}
      />

      <div className="horizontalLine"></div>

      {!revealQuestionResult && (
        <Form
          onSubmit={() => this.handleSubmit}
          doSubmit={doSubmit}
          data={data}
          errors={errors}
          schema={schema}
          formElements={formElements}
          itemClassName="options"
          submitClassName=""
        ></Form>
      )}

      {revealQuestionResult && (
        <GameQuestionResult
          questionResult={questionResult}
          translation={props.translation}
        />
      )}
    </div>
  );
};

export default GameQuestionAnswerKeypad;

import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import parse from "html-react-parser";

const VideoModal = (props) => {
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState(null);
  const [heading, setHeading] = useState("");
  const [videoOpenCss, setVideoOpenCss] = useState("");

  useEffect(() => {
    setModal(props.modal);
  }, [props.modal]);

  useEffect(() => {
    setVideos(props.videos);

    let myHeading = "";
    if (props.videos) {
      if (props.videos.length > 0) {
        myHeading = props.videos[0].heading;
      }
    }
    setHeading(myHeading);

  }, [props.videos]);

  const toggle = () => {
    setModal(!modal);
    setVideo(null);
    setVideoOpenCss("");

    props.onModalClose();
  };

  const handlePlayVideo = (item) => {
    setVideo(item);

    if (item) {
      // props.onShowVideo();
      setVideoOpenCss("videoOpen");
    } else {
      setVideoOpenCss("");
    }
  };

  const getSelected = (item) => {
    var className = "expertCard";

    if (video && item.videoUrl == video.videoUrl) {
      className += " selected";
    }

    return className;
  };

  return (
    <Modal
      isOpen={modal}
      size="lg"
      toggle={toggle}
      centered={false}
      modalClassName="expertsModal"
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody className={videoOpenCss}>

        <div className="videoSection">
          <div className="question">{parse(heading)}</div>
          {video &&
            <div><video controls autoPlay src={video.videoUrl}></video>
              <div className="name">{parse(video.body)}</div>
            </div>
            }
          </div>
        <div>
          <div className="chooseStrip">
            <img src={props.translation('viewingVideo.chooseText')} className="text" alt="" />
            <img src="Assets/images/pinkStrip.svg" className="bg" alt="" />
          </div>
          <div className="expertsList">
            {props.videos &&
              props.videos.map((item) => (
                <div
                  className={getSelected(item)}
                  key={item.id}
                  onClick={() => handlePlayVideo(item)}
                >
                  <div className="expertImageAndName">
                    <div className="expertImg">
                      <img src={item.thumbnailUrl} alt="" />
                    </div>
                    <div className="expertName">{parse(item.thumbnailLabel)}</div>
                  </div>
                  <div className="arrow">
                    <img src="Assets/images/arrow_orange.svg" alt="" />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;

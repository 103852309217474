import React from "react";
import GameToggleButtons from "./GameToggleButtons";

const GameRoundFinish = (props) => {
  const getClassName = () => {
    var myClassName = "";

    if (props.viewingVideo) {
      myClassName += "videoOpen ";
    }

    if (props.endRoundStat.rank && props.endRoundStat.rank == 1) {
      myClassName += "first ";
    }

    return myClassName;
  };

  return (
    <div className={getClassName() + "endOfRound_winner pl-5 pr-5"}>
      <div className="endOfRoundPage mobile pl-5 pr-5">
        <div className="logo">
          <img src={props.translation('logoSm')} alt="RISE Logo" />
        </div>
        <div className="roundAndQuestionNumber">
          <div className="roundNumber roundAndQuestion d-flex align-items-center">
            <div className="label">
              <img src={props.translation('endOfRound.endOfRoundHeading_mImg')} alt="" />
            </div>
            <div className="number">{props.displayRoundId}</div>
          </div>
        </div>
        <div className="options">
          <img
            src={props.translation('viewingVideoImg')}
            className="afterAnswer videoOpen"
            alt=""
          />
          <img
            src={props.translation('endOfRound.yourScoreSmall')}
            className="yourScore"
            alt=""
          />
        </div>
        <div className="btnSection">
          <p>
            {props.endRoundStat.points}
            <span>Pts</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GameRoundFinish;

import React, { useState, useEffect } from "react";
import { Button, Label, FormGroup } from "reactstrap";
import Joi from "joi-browser";
import Input from "./input";
import Radio from "./radio";
import CustomRadio from "./customRadio";

const Form = (props) => {
  const [data, setData] = useState(props.data);
  const [schema, setSchema] = useState(props.schema);
  const [errors, setErrors] = useState(props.errors);

  useEffect(() => {
    setData(props.data);
    setSchema(props.schema);
    setErrors(props.errors);
  }, [props]);

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);

    if (!error) return null;

    const myErrors = {};
    for (let item of error.details) myErrors[item.path[0]] = item.message;

    return myErrors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const mySchema = { [name]: schema[name] };
    const options = { abortEarly: true };
    const { error } = Joi.validate(obj, mySchema, options);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const myErrors = validate();

    setErrors(myErrors || {});

    if (myErrors) return;
    props.doSubmit(data);
  };

  const handleChange = ({ currentTarget: input }) => {
    const myErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) myErrors[input.name] = errorMessage;
    else delete myErrors[input.name];

    const myData = { ...data };

    myData[input.name] = input.value;

    setData(myData);
    setErrors(myErrors);
  };

  const renderInput = (name, label, type = "text", customLabel = null) => {
    return (
      <Input
        onChange={handleChange}
        value={data[name]}
        key={label}
        name={name}
        type={type}
        label={label}
        customLabel={customLabel}
        error={errors[name]}
      />
    );
  };

  const renderButton = (
    label,
    className = "btn btn-primary",
    visible = true
  ) => {
    if (visible) {
      return (
        <Button key={label} disabled={validate() != null} className={className}>
          {label}
        </Button>
      );
    }
    return "";
  };

  const renderCustomButton = (
    label,
    className = "btn",
    visible = true,
    allowSubmit = true
  ) => {
    if (visible) {
      return (
        <div className="btnSection" key={label}>
          {allowSubmit && (
            <button disabled={validate() != null} className={className}>
              {label}
            </button>
          )}
          {!allowSubmit && (
            <button
              disabled={validate() != null}
              className={className}
              onClick={(e) => e.preventDefault()}
            >
              {label}
            </button>
          )}
        </div>
      );
    }
    return "";
  };

  const renderCustomRadio = (
    name,
    value,
    label,
    group,
    background,
    selectedBackground,
    selectedBorder,
    selectedFontColor,
    soundEffects
  ) => {
    return (
      <CustomRadio
        onChange={handleChange}
        value={value}
        key={label}
        id={name}
        name={group}
        type="radio"
        label={label}
        background={background}
        selectedBackground={selectedBackground}
        selectedBorder={selectedBorder}
        selectedFontColor={selectedFontColor}
        soundEffects={soundEffects}
        error={errors[name]}
        checked={data[group] == value}
      />
    );
  };

  const renderRadio = (name, value, label, group) => {
    return (
      <FormGroup tag="fieldset" key={value}>
        <Radio
          onChange={handleChange}
          value={value}
          key={label}
          id={name}
          name={group}
          type="radio"
          label={label}
          error={errors[name]}
          checked={data[group] == value}
        />
      </FormGroup>
    );
  };

  const rows = [];
  const submitRows = [];
  props.formElements.forEach((el) => {
    switch (el.type) {
      case "input":
      case "password":
      case "number":
        rows.push(renderInput(el.name, el.label, el.type, el.customLabel));
        break;

      case "radio":
        rows.push(renderRadio(el.name, el.value, el.label, el.group));
        break;

      case "customRadio":
        rows.push(
          renderCustomRadio(
            el.name,
            el.value,
            el.label,
            el.group,
            el.background,
            el.selectedBackground,
            el.selectedBorder,
            el.selectedFontColor,
            el.soundEffects
          )
        );
        break;

      case "button":
        submitRows.push(renderButton(el.label, el.class, el.visible));
        break;

      case "customSubmit":
        submitRows.push(
          renderCustomButton(el.label, el.class, el.visible, el.allowSubmit)
        );
        break;

      default:
        break;
    }
  });

  return (
    <form onSubmit={handleSubmit} className={props.className}>
      <div className={props.itemClassName}>{rows}</div>
      <div className={props.submitClassName}>{submitRows}</div>
      {errors.message && (
        <Label className="text-danger">{errors.message}</Label>
      )}
    </form>
  );
};

export default Form;

import React from "react";
import { FormGroup, Label, FormFeedback, Input as InputEl } from "reactstrap";

const Radio = ({ name, group, id, label, error, ...rest }) => {
  let inputClass = "form-control";
  if (error) inputClass += " is-invalid";

  return (
    <FormGroup check>
      <Label check>
        <InputEl
          name={name}
          id={id}
          className={inputClass}
          type="radio"
          {...rest}
        />
        {label}
      </Label>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

export default Radio;

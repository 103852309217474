import React, { useEffect, useState } from "react";
import game from "../../services/gameService";
import _ from "lodash";
import constants from "../customized/constants";
import GamePlayersCheckInStatus from "./GamePlayersCheckInStatus";
import GameCheckInHeader from "./GameCheckInHeader";

const GameCheckIn = (props) => {

  const [showPlayerStatus, setShowPlayerStatus] = useState(game.isRep);

  const handleCheckIn = () => {
    props.onCheckIn();
  };

  return (
    <React.Fragment>
      {!showPlayerStatus && (
        <div className="checkInPage mobile pl-5 pr-5">
          <div className="">
            <div className="logo">
              <img src={props.translation('logoSm')} alt="RISE Logo" />
            </div>
            <div className="playerCheckInHeading">
              <img src={props.translation('checkingIn.playersCheckingInSm')} alt="" />
            </div>
            <div className="lungsImg">
              <img src={props.translation('checkingIn.lungsImgLg')} alt="" />
            </div>
          </div>
        </div>
      )}

      {showPlayerStatus && (
        <div className="checkInPage pl-5 pr-5">
          <GameCheckInHeader gameRep={props.gameRep} gameModerator={props.gameModerator} translation={props.translation}/>
          <div className="body pl-5 pr-5">
            <div className="left">
              <GamePlayersCheckInStatus gamePlayers={props.gamePlayers} translation={props.translation}/>
            </div>
            <div className="right">
              <div className="top">
                <img src={props.translation('checkingIn.lungsImgLg')} alt="" />
              </div>
              <div className="bottom">
                <img
                  className="imgBtn"
                  src={props.translation('nextButton')}
                  alt=""
                  onClick={() => handleCheckIn()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GameCheckIn;

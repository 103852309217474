import React from "react";

export default function Arrow(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="74.709px"
      height="44.988px"
      viewBox="0 0 74.709 44.988"
    >
     
      <polygon
        fill={props.fill}
        points="9.512,42.998 21.831,22.5 1.991,2.178 52.765,1.99 72.719,22.92 60.287,42.811 "
      />
    </svg>
  );
}

import { Howl } from "howler";
import React, { useState, useEffect } from "react";
import { FormGroup, Label, FormFeedback, Input as InputEl } from "reactstrap";
import constants from "../customized/constants";

const CustomRadio = ({
  name,
  group,
  id,
  label,
  background,
  selectedBackground,
  selectedBorder,
  selectedFontColor,
  soundEffects,
  error,
  ...rest
}) => {
  let inputClass = "form-control";
  if (error) inputClass += " is-invalid";

  const getStyle = () => {
    if (selectedBackground && selectedBorder && selectedFontColor && checked) {
      return {
        background: selectedBackground,
        border: `3px solid ${selectedBorder}`,
        color: selectedFontColor,
      };
    }

    return { background: background };
  };

  const [checked, setChecked] = useState(false);

  const [playActive, setPlayActive] = useState();
  const [playOn, setPlayOn] = useState();


  useEffect(() => {
    let myPlayActive = new Howl({
      src: "/Assets/sounds/pop-down.mp3",
      volume: constants.uiSoundLevel,
      preload: true,
    });

    let myPlayOn = new Howl({
      src: "/Assets/sounds/pop-up-on.mp3",
      volume: constants.uiSoundLevel,
      preload: true,
    });

    setPlayActive(myPlayActive);
    setPlayOn(myPlayOn);

  }, [])

  useEffect(() => {
    setChecked(rest.checked);
  }, [rest.checked]);

  return (
    <label
      className="optionContainer customRadio"
      onMouseDown={() => {
        if (soundEffects) {
          playActive.play();
        }
      }}
      onMouseUp={() => {
        if (soundEffects) {
          if (checked) {
          } else {
            playOn.play();
          }
        }
      }}
    >
      <InputEl
        name={name}
        id={id}
        className={inputClass}
        type="radio"
        checked={checked}
        {...rest}
      />
      <span className="checkmark"></span>
      <div className="option" style={getStyle()}>
        {label}
      </div>
    </label>
  );
};

export default CustomRadio;

import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Route, useParams, useLocation, matchPath } from "react-router";
import Layout from './components/Layout';
import './custom.css'
import 'react-toastify/dist/ReactToastify.css';
import auth from "./services/authService";
import { useHistory, Switch, Redirect } from 'react-router-dom';
import GamePlay from './components/game/GamePlay';
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import storageService from "./services/storageService";
import game from "./services/gameService";
import { gameHub } from "./components/config.json";
import GameValidateCode from './components/game/GameValidateCode';
import ProtectedGameRoute from './components/ProtectedGameRoute';
import GameAboutToStart from './components/game/GameAboutToStart';
import { toast } from "react-toastify";
import ConnectionStatusModal from './components/ConnectionStatusModal';
import { Howl, Howler } from 'howler';
import constants from './components/customized/constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function App() {
  const history = useHistory()

  const { t, i18n } = useTranslation('translations');

  const [urlLang, setUrlLang] = useState(constants.languages.en.abbr);

  const match = matchPath(history.location.pathname, {
    // You can share this string as a constant if you want
    path: "/:lang"
  });

  const [currentLanguage, setCurrentLanguage] = useState(storageService.getLanguage().abbr);

  useEffect(() => {

    // var language = storageService.getLanguage();

    // handleSetLanguage(language.abbr);

  }, []);


  const handleLanguageChange = (lang) => {

    var languageFromUrl = match && match.params && match.params.lang ? match.params.lang : currentLanguage;

    if (languageFromUrl && languageFromUrl != lang) {
      var url = history.location.pathname.replace(`/${languageFromUrl}/`, `/${lang}/`);

      window.location.replace(url);
    }
  }

  const handleSetLanguage = (lang) => {

    i18n.changeLanguage(lang);

    // storageService.setLanguage(lang);

    setCurrentLanguage(lang);

  }

  //#region States
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const [playMyIntro, setPlayMyIntro] = useState();

  const [showConnectionModal, setShowConnectionModal] = useState(false);

  const [connection, setConnection] = useState(null);
  const latestConnection = useRef(null);
  latestConnection.current = connection;

  const [connectionState, setConnectionState] = useState();
  const latestConnectionState = useRef(null);
  latestConnectionState.current = connectionState;

  const [loading, setLoading] = useState(false);
  const latestLoading = useRef(false);
  latestLoading.current = loading;

  const [connectionTrigger, setConnectionTrigger] = useState();

  const [gameJwt, setGameJwt] = useState(storageService.getGameToken());
  const latestGameJwt = useRef(null);
  latestGameJwt.current = gameJwt;

  const [gameStatus, setGameStatus] = useState();
  const [gameToken, setGameToken] = useState(
    storageService.getGameToken(false)
  );

  const [isRep, setIsRep] = useState(game.isRep);
  const latestisRep = useRef(false);
  latestisRep.current = isRep;

  const [gameRep, setGameRep] = useState();
  const [gameModerator, setGameModerator] = useState();

  /**New */

  const [displayQuestionId, setDisplayQuestionId] = useState(0);
  const [displayRoundId, setDisplayRoundId] = useState(0);

  const [isLastRound, setIsLastRound] = useState(false);

  const [question, setQuestion] = useState(null);
  const latestQuestion = useRef(null);
  latestQuestion.current = question;

  const [questionResult, setQuestionResult] = useState();
  const latestQuestionResult = useRef(null);
  latestQuestionResult.current = questionResult;

  const [viewingVideo, setViewingVideo] = useState(false);
  const latestViewingVideo = useRef(false);
  latestViewingVideo.current = viewingVideo;

  const [roundVideos, setRoundVideos] = useState([]);
  const latestRoundVideos = useRef([]);
  latestRoundVideos.current = roundVideos;

  const [playersAnswerStatus, setPlayersAnswerStatus] = useState([]);
  const latestPlayersAnswerStatus = useRef([]);
  latestPlayersAnswerStatus.current = playersAnswerStatus;

  const [revealQuestionResult, setRevealQuestionResult] = useState(false);
  const latestRevealQuestionResult = useRef(false);
  latestRevealQuestionResult.current = revealQuestionResult;

  const [endRoundStat, setEndRoundStat] = useState();
  const latestEndRoundStat = useRef(null);
  latestEndRoundStat.current = endRoundStat;

  const [endGameStat, setEndGameStat] = useState();
  const latestEndGameStat = useRef(null);
  latestEndGameStat.current = endGameStat;

  const [playersRoundStat, setPlayersRoundStat] = useState();
  const latestPlayersRoundStat = useRef(null);
  latestPlayersRoundStat.current = playersRoundStat;

  const [gamePlayers, setGamePlayers] = useState([]);
  const latestGamePlayers = useRef(null);
  latestGamePlayers.current = gamePlayers;

  //#endregion

  const handleSetLoading = (isLoading = false) => {
    latestLoading.current = false;
    setLoading(isLoading);
  };

  //#region Effects
  useEffect(() => {
    setGameJwt(storageService.getGameToken());
    setGameToken(storageService.getGameToken(false));

    return function cleanup() {
      if (connection && connection.state == HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [])

  useEffect(() => {
    var languageFromUrl = match && match.params && match.params.lang ? match.params.lang : currentLanguage;
    if (languageFromUrl && languageFromUrl != currentLanguage) {
      let myLanguage = constants.getLanguageFromAbbr(languageFromUrl);
      if (myLanguage && myLanguage.abbr) {

        handleSetLanguage(myLanguage.abbr);
      }
    }

    return history.listen((location) => {

      const user = auth.getCurrentUser();
      setIsLoggedIn(user);
    });
  }, [history]);


  useEffect(() => {
    Howler.mute(!storageService.getAudioPref());

    let myPlayMyIntro = new Howl({
      src: "/Assets/sounds/calm_jazz_piano.wav",
      volume: constants.soundLevel,
      preload: true,
      loop: true
    });

    setPlayMyIntro(myPlayMyIntro);
  }, [])

  //Load Game Player Status
  useEffect(() => {
    if (gameStatus == null) {
      return;
    }

    if (gameStatus.complete) {
      var stats = gameStatus.playerPointStats;
      if (!isRep) {
        stats = stats[0];
      }

      latestEndRoundStat.current = null;
      setEndRoundStat(null);

      latestEndGameStat.current = stats;
      setEndGameStat(stats);
    }

    if (gameStatus.finished) {
      return;
    }

    if (gameStatus.active) {
      if (gameStatus.started) {

        if (playMyIntro && playMyIntro.playing() && !gameStatus.aboutToStart) {
          playMyIntro.fade(constants.soundLevel, 0, 3000);
          setTimeout(function () {
            playMyIntro.stop();
          }, 3100);
        }

        if (gameStatus.questionId == null && isRep) {
          const loadPlayerStatus = async () => {
            var status = await game.gamePlayerStatus();
            handleGamePlayers(status);
          };

          loadPlayerStatus();
        }

        if (gameStatus.questionId > 0) {
          loadCurrentQuestion();
        }
      } else {
        if (latestisRep && latestisRep.current) {
          playMyIntro.play();
        }
      }
    }
  }, [gameStatus]);

  useEffect(() => {

    document.body.classList.remove("incorrect");
    document.body.classList.remove("correct");

    if (questionResult && latestRevealQuestionResult.current) {
      if (questionResult.correct) {
        document.body.classList.add("correct");
      } else {
        document.body.classList.add("incorrect");
      }
    }

  }, [questionResult, revealQuestionResult])

  useEffect(() => {
    if (gameToken == null || gameStatus == null || (latestConnection.current != null && latestConnection.current.state == HubConnectionState.Connected)) {
      return;
    }

    var gameHubConnectionUrl = `${gameHub}?GameId=${gameStatus.id}`;

    const newConnection = new HubConnectionBuilder()
      .withUrl(gameHubConnectionUrl, {
        accessTokenFactory: () => gameToken,
      })
      .withAutomaticReconnect()
      .build();

    latestConnection.current = newConnection;
    setConnection(newConnection);

    return function cleanup() {
      if (connection && connection.state == HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [gameToken, gameStatus, connectionTrigger]);

  async function startConnection() {
    try {
      if (connection) {
        connection
          .start()
          .then((result) => {
            console.log("Connected!");

            setConnectionState(HubConnectionState.Connected);
            latestConnectionState.current = HubConnectionState.Connected;

            connection.on("NextQuestion", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              handleSetQuestion(message);
            });

            connection.on("ViewingVideo", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              latestViewingVideo.current = true;
              setViewingVideo(true);
            });

            connection.on("PlayerStatusUpdate", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              handleGamePlayers(message);
            });

            connection.on("PlayerAnswerStatusUpdate", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              latestPlayersAnswerStatus.current = message;
              setPlayersAnswerStatus(message);
            });

            connection.on("ViewingVideoEnd", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              latestViewingVideo.current = false;
              setViewingVideo(false);
            });

            connection.on("RevealQuestionResults", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              latestRevealQuestionResult.current = true;
              setRevealQuestionResult(true);

              //If Answer Not Submitted
              var result = latestQuestionResult.current;
              if (result == null) {
                result = { correct: false };
              }

              handleRevealResult(result);
            });

            connection.on("EndGame", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              latestEndGameStat.current = message;
              setEndGameStat(message);
              handleSetQuestion(null);
            });

            connection.on("EndGameRound", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              latestEndRoundStat.current = message;
              setEndRoundStat(message);

              handleSetQuestion(null);
            });

            connection.onclose((message) => {
              console.log("onclose");

              setConnectionState(HubConnectionState.Disconnected);
              latestConnectionState.current = HubConnectionState.Disconnected;
            });

            connection.onreconnecting((message) => {
              console.log("onreconnecting");

              setConnectionState(HubConnectionState.Reconnecting);
              latestConnectionState.current = HubConnectionState.Reconnecting;
            });

            connection.onreconnected(connectionId => {
              console.log("reconnected");

              setConnectionState(HubConnectionState.Connected);
              latestConnectionState.current = HubConnectionState.Connected;
            });

            connection.on("StartGame", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              setGameStatus(message);
            });

            connection.on("AboutToStart", (message) => {
              let check = checkTokens();
              if (!check) {
                return;
              }

              setGameStatus(message);
            });
          })
          .catch((e) =>
            console.log("Connection failed: ", e));
      }
    } catch (err) {
      setTimeout(() => startConnection(), 5000);
    }
  };

  useEffect(() => {
    startConnection();
  }, [connection]);

  useEffect(() => {
    if (gameToken == null) {
      return;
    }

    setIsRep(game.isRep());

    loadGameStatus();

    setConnectionTrigger((r) => r + 1);

    return function cleanup() {
      setConnectionTrigger(-1);
    };
  }, [gameToken]);

  //#endregion


  //#region Handles

  const loadCurrentQuestion = async () => {
    var question = await game.currentQuestion();
    handleSetQuestion(question);

    latestQuestionResult.current = question
      ? question.object.answerResponse
      : null;
    setQuestionResult(question ? question.object.answerResponse : null);
  };

  const loadGameStatus = async () => {
    var status = await game.gameStatus();
    if (!status) {
      storageService.removeGameToken();
      setGameJwt(null);
      setGameToken(null);
      setGameStatus(null);
      history.push('/login');
      return;
    }

    setGameStatus(status);

    //reset Connection
    // setConnectionTrigger((r) => r + 1);
  };

  const handleConnectionTrigger = (t) => {
    setConnectionTrigger(t);
  }

  const handleGamePlayers = (value) => {
    var myGamePlayers = [];

    _.forEach(value, function (item) {
      if (item.gamePlayerRoles && item.gamePlayerRoles.length > 0) {
        var isPlayer = item.gamePlayerRoles.some(
          (r) => r.id === constants.role.GamePlayer
        );

        if (isPlayer) {
          myGamePlayers.push(item);
        }

        var isPlayerRep = item.gamePlayerRoles.some(
          (r) => r.id === constants.role.Rep
        );
        if (isPlayerRep) {
          setGameRep(item);
          return;
        }

        var isModerator = item.gamePlayerRoles.some(
          (r) => r.id === constants.role.GameModerator
        );
        if (isModerator) {
          setGameModerator(item);
          return;
        }
      }
    });

    setGamePlayers(myGamePlayers);
  };

  const handleErrorResponse = (error) => {

    if (!error) {
      return;
    }

    switch (error.response.status) {
      case 401:
        setGameJwt(null);
        setGameStatus(null);
        setGameToken(null);
        break;
      case 400:
      case 404:
        toast.error(error.response.data.message);
        break;
      default:
        break;
    }
  }

  const checkTokens = () => {
    if (storageService.getGameToken() == null) {
      setGameJwt(null);
      setGameStatus(null);
      setGameToken(null);

      return false;
    }

    return true;
  }

  //#region Question Handles

  const handleSetQuestion = (result) => {
    if (result) {
      setDisplayQuestionId(result.questionId);
      setDisplayRoundId(result.roundId);
    }

    var question = result && result.object ? result.object : null;

    if (result && result.endGame) {
      latestQuestion.current = null;
      setQuestion(null);

      var stats = result.playerPointStats;
      if (!isRep) {
        stats = stats[0];
      }

      latestEndRoundStat.current = null;
      setEndRoundStat(null);

      latestEndGameStat.current = stats;
      setEndGameStat(stats);
    } else if (result && result.endRound) {
      latestQuestion.current = null;
      setQuestion(null);

      var stats = result.playerPointStats;
      if (!isRep) {
        stats = stats[0];
      }

      latestEndRoundStat.current = stats;
      setEndRoundStat(stats);
    } else {
      latestQuestion.current = question;
      setQuestion(question);

      latestQuestionResult.current = null;
      setQuestionResult(null);

      handleRevealResult(latestQuestionResult.current);
    }

    latestPlayersAnswerStatus.current = result && result.playerAnswerStatus ? result.playerAnswerStatus : [];
    setPlayersAnswerStatus(result && result.playerAnswerStatus ? result.playerAnswerStatus : []);

    latestRoundVideos.current = result ? result.roundVideos : null;
    setRoundVideos(result ? result.roundVideos : null);

    latestViewingVideo.current = false;
    setViewingVideo(false);

    latestRevealQuestionResult.current = result ? result.reveal : null;
    setRevealQuestionResult(result ? result.reveal : null);

    setIsLastRound(result ? result.lastRound : false);
  };

  const handleRevealResult = (result) => {
    setQuestionResult(result);
  }

  const handleGameStart = async () => {
    handleSetLoading(true);
    try {
      var result = await game.startGame();
      setGameStatus(result);

    } catch (error) {
      handleErrorResponse(error);
    }
    handleSetLoading(false);
  };

  const handleStartQuestion = async () => {
    handleSetLoading(true);
    try {
      const result = await game.nextQuestion();
      handleSetQuestion(result);
    } catch (error) {
      handleErrorResponse(error);
    }
    handleSetLoading(false);
  };

  const handleNextQuestion = async () => {

    handleSetLoading(true);

    try {
      var question = await game.nextQuestion();
      if (question != null) {
        handleSetQuestion(question);
      }
    } catch (error) {
      handleErrorResponse(error);
    }

    handleSetLoading(false);
  };

  //#endregion

  const handleNextRound = async () => {
    try {
      var question = await game.nextQuestion({ startNextRound: true });
      if (question != null) {
        handleSetQuestion(question);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleEndRound = async () => {
    handleSetLoading(true);
    try {
      var question = await game.endRound();
      if (question != null) {
        handleSetQuestion(question);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
    handleSetLoading(false);
  };

  const handleEndGame = async () => {
    handleSetLoading(true);
    try {
      var question = await game.endGame();
      if (question != null) {
        handleSetQuestion(question);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
    handleSetLoading(false);
  };

  const handleSubmitAnswer = async (data) => {

    try {
      var result = await game.submitAnswer(data);
      if (result != null) {
        latestQuestionResult.current = result;
        setQuestionResult(result);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleReveal = async () => {

    handleSetLoading(true);

    try {
      var result = await game.revealAnswer({ startNextRound: true });
      if (result != null) {
        latestRevealQuestionResult.current = true;
        setRevealQuestionResult(true);
      }
    } catch (error) {
      handleErrorResponse(error);
    }

    handleSetLoading(false);

  };

  const handleCheckIn = async () => {
    handleSetLoading(true);
    try {
      var result = await game.aboutToStartGame();
      setGameStatus(result);
    } catch (error) {
      handleErrorResponse(error);
    }
    handleSetLoading(false);
  };

  const handleShowVideo = async () => {
    try {
      var result = await game.viewVideo();
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  const handleEndVideo = async () => {
    try {
      var result = await game.viewVideoEnd();
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleValidateCode = (result) => {

    let token = result.object;
    // setGameToken(token);
    // setGameJwt(token);
    if (token) {

      const url = `/${result.language.toLowerCase()}/play`;
      // <Redirect to={url} />
      window.location.replace(url);

      history.push(url);
    }
  };

  const handleLogout = () => {
    storageService.removeGameToken();

    setGameJwt(null);
    setGameToken(null);
    setGameStatus(null);

    history.push(`/${currentLanguage}/login`);
  };

  const handleShowConnectionModal = () => {
    setShowConnectionModal(true);
  };

  const handleAudioPrefChange = () => {
    let audio = storageService.getAudioPref();
    Howler.mute(!audio);
  }

  //#endregion

  return (

    <Layout
      currentLanguage={currentLanguage}
      gameJwt={gameJwt}

      onLanguageChange={handleLanguageChange}

      onLogout={handleLogout}
      onAudioPrefChange={handleAudioPrefChange}
      onShowConnectionModal={handleShowConnectionModal}>

      <ConnectionStatusModal
        gameJwt={gameJwt}
        connection={latestConnection.current}
        connectionState={latestConnectionState.current}
        modal={showConnectionModal}
        translation={t}
      />


      <Route path={['/', '/:lang']}>
        <Route exact path='/game-about-to-start' component={GameAboutToStart} />
        {/* 
        <Route path='/login/:lang/:gameCode/:playerCode'
          render={(props) => (
            <GameValidateCode
              history={history}
              gameJwt={latestGameJwt.current}
              onValidateCode={handleValidateCode}
              component={GameValidateCode}
            />)}
        /> */}
        <Route path={['/:lang/login']}
          render={(props) => (
            <GameValidateCode
              currentLanguage={currentLanguage}
              history={history}
              gameJwt={latestGameJwt.current}
              gameStatus={gameStatus}
              onValidateCode={handleValidateCode}
              component={GameValidateCode}
              translation={t}
            />)}
        />

        <ProtectedGameRoute exact path={["/", "/play"]}

          translation={t}
          connection={latestConnection.current}
          component={GamePlay}
          currentLanguage={currentLanguage}

          endGameStat={latestEndGameStat.current}
          endRoundStat={latestEndRoundStat.current}

          displayRoundId={displayRoundId}
          displayQuestionId={displayQuestionId}

          gameRep={gameRep}
          gameModerator={gameModerator}

          gameJwt={gameJwt}
          gameToken={gameToken}
          gameStatus={gameStatus}
          gamePlayers={latestGamePlayers.current}

          isRep={isRep}
          isLastRound={isLastRound}

          loading={latestLoading.current}

          onCheckIn={handleCheckIn}
          onEndGame={handleEndGame}
          onEndRound={handleEndRound}
          onEndVideo={handleEndVideo}

          onShowVideo={handleShowVideo}
          onGameStart={handleGameStart}
          onStartQuestion={handleStartQuestion}
          onNextQuestion={handleNextQuestion}
          onNextRound={handleNextRound}
          onReveal={handleReveal}
          onSubmitAnswer={handleSubmitAnswer}
          onValidateCode={handleValidateCode}

          playersAnswerStatus={latestPlayersAnswerStatus.current}

          question={latestQuestion.current}
          questionResult={latestQuestionResult.current}

          revealQuestionResult={latestRevealQuestionResult.current}
          roundVideos={latestRoundVideos.current}

          viewingVideo={latestViewingVideo.current}
        />
      </Route>
    </Layout>

  );
}

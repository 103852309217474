import React, { useEffect, useState } from "react";
import _ from "lodash";

const GameLeaderBoard = (props) => {
  const [endGameStats, setEndGameStats] = useState(props.endRoundStat);
  const [top5Players, setTop5Players] = useState([]);
  const [restPlayers, setRestPlayers] = useState([]);

  useEffect(() => {
    var players = props.endGameStat;
    if (players.length == 0) {
      setEndGameStats([]);
      return;
    }

    var top5 = [];
    var rest = [];

    for (var i = 0; i < players.length; i++) {
      let playerPoint = players[i];
      if (i < 1) {
        top5.push(playerPoint.players);
        continue;
      }

      rest.push(playerPoint.players);
    }

    setTop5Players(_.flatten(top5));
    setRestPlayers(_.flatten(rest));

    setEndGameStats(props.endGameStat);
  }, [props.endGameStat]);

  return (
    <div className="endOfGamePage pl-5 pr-5">
      <header className="pt-4 d-flex">
        <div className="logo">
          <img src={props.translation('logoLg')} alt="RISE Logo" />
        </div>
        <div className="hostAndModeratorName">
          <div className="hostName hostAndModerator d-flex align-items-center">
            <div className="label">
              <img src={props.translation('endOfGame.endOfGameImg')} alt="" />
            </div>
          </div>
        </div>
      </header>
      <div className="body pl-5 pr-5">
        <div className="left">
          <div className="playerCheckInHeading">
            <img src={props.translation('endOfGame.gameWinnerIcon')} alt="" />
          </div>
          <div className="playerCheckInList">
            {top5Players.map((item) => (
              <div className="arrowAndPlayerName" key={item.id}>
                <div className="playerNameText">
                  <div className="label"> {item.fullName}</div>
                </div>
                <div className="playerScore">
                  {item.points}
                  <span>pts</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          <div className="top justify-content-end">
            {restPlayers && restPlayers.length > 0 && (
              <div className="otherScoreBoard">
                <div className="heading">{props.translation('endOfRound.otherScore')}</div>
                <div className="horizontalLine"></div>
                <div className="playerCheckInList">
                  {restPlayers.map((item) => (
                    <div className="arrowAndPlayerName" key={item.id}>
                      <div className="playerNameText">
                        <div className="label">{item.fullName}</div>
                      </div>
                      <div className="playerScore">
                        {item.points}
                        <span>pts</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameLeaderBoard;

import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";

const GameQuestionAnswer = (props) => {
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

  const dOptionsSectionRef = useRef(null);
  const minFontSize = 12;
  const optionClassName = "options";
  const optionTextClassName = "optionText";

  let tallestOptionText = useRef();
  let tallestOption = useRef();

  let optionTextRefs = useRef([]);

  useEffect(() => {
    setTimeout(function () {
      setFontSize();
    }, 1);

    return () => {};
  }, [props.question.answers]);

  useEffect(() => {
    setShowCorrectAnswer(props.showCorrectAnswer);
  }, [props.showCorrectAnswer]);

  const getLabelStyle = (item) => {
    var style = { background: item.label.color };

    return style;
  };

  const getLabelClass = (item) => {
    var style = optionClassName;

    if (showCorrectAnswer && item.correctAnswer) {
      style += " correct";
    }

    return style;
  };

  const getOptionStyle = (item) => {
    var style = {};

    if (showCorrectAnswer && item.correctAnswer) {
      style.background = item.label.colorCorrect;
    }

    return style;
  };

  const setFontSize = () => {
    if(!(dOptionsSectionRef && dOptionsSectionRef.current)){
      return;
    }

    let maxHeight = 0;

    //Find the Tallest OptionText
    for (let i = 0; i < dOptionsSectionRef.current.children.length; i++) {
      var el = dOptionsSectionRef.current.children[i];

      for (let j = 0; j < el.children.length; j++) {
        var option = el.children[j];
        if (option.className != optionTextClassName) {
          continue;
        }

        var optionText = el.children[j].children[0];
        optionTextRefs.current.push(optionText);

        if (optionText.clientHeight < option.clientHeight) {
          continue;
        }

        if (maxHeight <= optionText.clientHeight) {
          maxHeight = optionText.clientHeight;
          tallestOption.current = option;
          tallestOptionText.current = optionText;
        }
      }
    }

    if (!(tallestOption && tallestOption.current)) {
      return;
    }

    const computedSize = window
      .getComputedStyle(tallestOptionText.current)
      .getPropertyValue("font-size");
    if (!computedSize) {
      return;
    }

    let fontSize = parseInt(computedSize.replace("px", ""));

    let containerHeight = tallestOption.current.clientHeight;
    let textHeight = tallestOptionText.current.clientHeight;

    let currentFontSize = fontSize;
    while (currentFontSize > minFontSize && textHeight >= containerHeight) {
      containerHeight = tallestOption.current.clientHeight;
      textHeight = tallestOptionText.current.clientHeight;

      currentFontSize -= 1;
      tallestOptionText.current.style.fontSize = currentFontSize + "px";
    }

    for (let i = 0; i < optionTextRefs.current.length; i++) {
      var optionText = optionTextRefs.current[i];
      optionText.style.fontSize = currentFontSize + "px";
    }

    optionTextRefs.current = [];
  };

  if (props.question && props.question.answers) {
    return (
      <div className="optionsSection" ref={dOptionsSectionRef}>
        {props.question.answers.map((item, i) => (
          <div
            className={getLabelClass(item)}
            key={item.id}
            style={getLabelStyle(item)}
          >
            <div className="optionCode">{parse(item.label.label)}</div>
            <div className={optionTextClassName} style={getOptionStyle(item)}>
              <p className="answers">{parse(item.answer)}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return "";
};

export default GameQuestionAnswer;
